import React from "react"
import '../styles/global.css'
import Links from "../components/Links"

export default function Home() {
  return (
    <section className="content">
      <h1>Hi, I'm Piyush <span className="wave">👋🏽</span></h1>
      <p>I'm an iOS Engineer working at <b><a href="https://apps.apple.com/gb/app/capital-one-uk/id481679012" target="_blank" rel="noreferrer">Capital One</a></b>.</p>
      <Links></Links>
    </section>
  )
}
