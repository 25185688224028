import React from 'react'
import * as styles from '../styles/links.module.css'

export default function Links() {
  return (
    <div className={styles.links}>
      <a href="https://github.com/piyushjainb" target="_blank" rel="noreferrer">GitHub</a>
      <a href="https://www.linkedin.com/in/piyush-jain-16953288" target="_blank" rel="noreferrer">LinkedIn</a>
      <a href="https://twitter.com/piyushjainb" target="_blank" rel="noreferrer">Twitter</a>
    </div>
  )
}
